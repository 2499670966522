var render = function render(){var _vm=this,_c=_vm._self._c;return _c('yandex-map',{attrs:{"settings":{
      mode: 'vector',
      location: {
        center: [_vm.centerCoords[1], _vm.centerCoords[0]],
        zoom: _vm.zoom,
      },
    }},model:{value:(_vm.map),callback:function ($$v) {_vm.map=$$v},expression:"map"}},[_c('yandex-map-default-scheme-layer',{attrs:{"settings":{customization: _vm.customization_map}}}),_c('yandex-map-default-features-layer'),(_vm.coordsInfo && _vm.coordsInfo.length)?_c('yandex-map-marker',{attrs:{"settings":{
             coordinates: [_vm.coordsInfo[1], _vm.coordsInfo[0]],
             draggable: true,
             onDragEnd: (coords) => _vm.onMarkerDragEnd(coords)
        }}},[_c('div',{staticClass:"marker"},[_c('svg',{attrs:{"width":"36","height":"36","viewBox":"0 0 36 36","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"36","height":"36","rx":"18","fill":"#848AFE"}}),_c('path',{attrs:{"d":"M13 20V26C13 26.2833 12.9042 26.5208 12.7125 26.7125C12.5208 26.9042 12.2833 27 12 27C11.7167 27 11.4792 26.9042 11.2875 26.7125C11.0958 26.5208 11 26.2833 11 26V11C11 10.7167 11.0958 10.4792 11.2875 10.2875C11.4792 10.0958 11.7167 10 12 10H19.175C19.4083 10 19.6167 10.075 19.8 10.225C19.9833 10.375 20.1 10.5667 20.15 10.8L20.4 12H25C25.2833 12 25.5208 12.0958 25.7125 12.2875C25.9042 12.4792 26 12.7167 26 13V21C26 21.2833 25.9042 21.5208 25.7125 21.7125C25.5208 21.9042 25.2833 22 25 22H19.825C19.5917 22 19.3833 21.925 19.2 21.775C19.0167 21.625 18.9 21.4333 18.85 21.2L18.6 20H13ZM20.65 20H24V14H19.575C19.3417 14 19.1333 13.925 18.95 13.775C18.7667 13.625 18.65 13.4333 18.6 13.2L18.35 12H13V18H19.425C19.6583 18 19.8667 18.075 20.05 18.225C20.2333 18.375 20.35 18.5667 20.4 18.8L20.65 20Z","fill":"white"}})])])]):_vm._e(),_c('yandex-map-listener',{attrs:{"settings":{ onClick: _vm.onClick }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }