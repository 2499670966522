import Vue from "vue";

export const editorProjectModule ={
    state: ()=>({
        complex: {
            markers: [],
            types_of_sales: {
                id: 0,
                name: 'Не важно'
            }
        },
        liters: {},
        litersSelect: [],
    }),
    getters:{
        getComplex: state => state.complex,
        getLiters: state => state.liters,
        getLitersSelect: state => state.litersSelect,
    },
    mutations:{
        setComplexAll: (state, resp) => {
            Vue.set(state, 'complex', resp)
        },
        setComplexObj: (state, obj) => {
            Vue.set(state, 'complex', {...state.complex, ...obj })
        },
        setLiters: (state, resp) => {
            Vue.set(state, 'liters', resp)
        },
        setLitersSelect: (state, resp) => {
            Vue.set(state, 'litersSelect', resp)
        },
    },
    namespaced:true
}