<script>
export default {
  name: "ambassadoriEu"
}
</script>

<template>
  <div class="ambassadori-eu">
  <div class="wrapper-privacy-header">
    <div class="privacy-header">
      Privacy Policy
    </div>
    <hr/>
  </div>
  <div class="privacy-description">
    <p>The administration of the "Ambassador Batumi Island" website is obligated to maintain your confidentiality on the internet. We consider it important to protect the data you provide. Our privacy policy is based on the General Data Protection Regulation (GDPR) of the European Union. The purpose of collecting your personal data is to provide information about "Ambassador Batumi Island" projects, communicate with website visitors, submit information requested by the user in accordance with user data processing, and perform other actions listed below.</p>
    <h1>Storage and Processing of Personal Data</h1>
    <p>We collect and process your personal data only at your discretion. With your permission, we may collect and process the following data: first and last name, email address, phone number. Collection and processing of your personal information is carried out in accordance with the legislation of the European Union and Georgia.</p>
    <p>The user agrees to receive advertising notifications to the email address and/or phone number they have specified;</p>
    <h1>Data Storage, Modification, and Deletion</h1>
    <p>The user who has provided their personal data to ambassadoriisland.com has the right to modify and delete them, as well as revoke consent for data processing at any time, without any explanation or justification. The period during which your personal data will be stored is the time when personal data is used, which is necessary for the website's core activities. The company will store your personal data for the duration of achieving the website's legitimate purpose, after which the website administration will permanently delete it. To access your personal data, you can contact the administration at the following address: marketing@islander.ge.</p>
    <h1>Processing of Technical Visit Data</h1>
    <p>Records of your IP address, visit time to ambassadoriisland.com, browser settings, operating system, and other technical information are stored in the database when you visit. This data is necessary for correctly displaying the website's content. These data cannot be used to identify the visitor's identity.</p>
    <h1>Minors' Personal Information</h1>
    <p>If you are the parent or legal guardian of a minor child and know that the child has provided their personal information without your consent, please contact us at: marketing@islander.ge. It is prohibited to enter personal data of minors without the consent of parents or legal guardians.</p>
    <h1>Cookies Processing</h1>
    <p>We use cookie files to correctly display website content and for browsing convenience. These are small files stored on your device. They help the website remember information about you, such as which language you use on the website and which pages you have already opened. This information will be useful during the next visit. Thanks to cookie files, browsing the website becomes much more convenient. You can learn more about these files here. You can set up cookie acceptance and blocking in your browser. Inability to receive cookie files may limit the website's functionality.</p>
    <h1>Personal Data Processing by Other Services</h1>
    <p>This website uses third-party online services that collect data independently from us. Such services include: Google Analytics, Facebook.com.</p>
    <p>Data collected by these services may be provided to other services of these organizations. They can use the data for advertising personalization of their advertising network. You can familiarize yourself with these organizations' user agreements on their websites. You can also opt out of their collection of your personal data. For example, you can view the Google Analytics Opt-out Browser Add-on here. We do not transfer personal data to other organizations or services not listed in this privacy policy. As an exception, collected data may be provided to state bodies upon legal request, which are authorized to request such information.</p>
    <h1>Links to Other Websites</h1>
    <p>Our website ambassadoriisland.com may contain links to other websites that are not under our control. We are not responsible for the content of these websites. We recommend that you familiarize yourself with the privacy policies of all websites you visit, if such a policy exists.</p>
    <h1>Changes to Privacy Policy</h1>
    <p>From time to time, our website ambassadoriisland.com may update our privacy policy. We monitor any changes in legislation related to personal data in the European Union and Georgia. In the event that you have entered any of your personal data on our website, you will be notified of changes to our privacy policy. If your personal data and more specifically, your contact information was incorrectly entered, we will not be able to contact you.</p>
    <h1>Feedback and Final Provisions</h1>
    <p>You can contact the "Ambassador Batumi Island" administration regarding any questions about the privacy policy: by calling + 995 32 20 55 000 or by filling out the contact form in the corresponding section of this website. If you do not agree with this privacy policy, you cannot use the services of ambassadoriisland.com. In this case, you should refrain from visiting our website.</p>
  </div>
</div>
</template>

<style scoped lang="scss">
.privacy-description {
  h1 {
    text-align: start;
    font-size: 18px;
    margin: 18px 0;
  }
  p {
    margin: 12px 0;
  }
}
</style>