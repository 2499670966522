<template>
  <div>
    <alert-modal
        v-if="visibleSuccessCopy"
        :title="$t('Успех')"
        :description="$t('СсылкаУспешноСкопирована')"
        :confirmText="$t('Закрыть')"
        :visible-cancel-bottom-button="false"
        @confirm-alert="visibleSuccessCopy=false"
        @cancel-alert="visibleSuccessCopy=false"
    />
    <div class="card-body" >
      <div class="feed-data-wrapper" v-if="complex.feed">
        <div class="feed-data-title"><h4>{{$t('КонтактныеДанныеДляФида')}}</h4></div>
        <div class="feed-data-body">
          <div class="form-group">
            <label>{{ $t('имя') }}</label>
            <input type="text" class="form-control"  v-model="complex.feed.name">
          </div>
          <div class="form-group">
            <label>{{$t('телефон')}}</label>
            <input type="text" class="form-control feed-phone"  v-model="complex.feed.phone">
          </div>
          <div class="form-group">
            <label>{{ $t('почта') }}</label>
            <input type="text" class="form-control"  v-model="complex.feed.email">
          </div>
          <div class="form-group">
            <label>{{$t('Город')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.city">
          </div>
          <div class="form-group">
            <label >{{$t('Район')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.district">
          </div>
          <div class="form-group">
            <label >{{$t('Улица')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.street">
          </div>
          <div class="form-group">
            <label>{{$t('ГодСдачи')}}</label>
            <input type="number" class="form-control"  v-model="complex.feed.built_year">
          </div>
          <div class="form-group">
            <label>{{$t('КварталСдачи')}}</label>
            <dropdown-component :multiple="false" :none="$t('НеВажно')" :elements="ready_quarter_list" v-model="complex.feed.ready_quarter"></dropdown-component>
          </div>
          <div class="form-group">
            <label>{{$t('АвитоId')}}</label>
            <input type="text" class="form-control" v-model="complex.feed.idavito">
          </div>
          <div class="form-group">
            <label>{{$t('АвитоPhone')}}</label>
            <input type="text" class="form-control" v-model="complex.feed.feedphones.phoneavito">
          </div>
          <div class="form-group">
            <label>{{$t('ЦианId')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.idcian">
          </div>
          <div class="form-group">
            <label>{{$t('ЦианPhone')}}</label>
            <input type="text" class="form-control" v-model="complex.feed.feedphones.phonecian">
          </div>
          <div class="form-group">
            <label>{{$t('YandexId')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.idyandex">
          </div>
          <div class="form-group">
            <label>{{$t('YandexPhone')}}</label>
            <input type="text" class="form-control" v-model="complex.feed.feedphones.phoneyandex">
          </div>
          <div class="form-group">
            <label>{{$t('DomclickId')}}</label>
            <input type="text" class="form-control"  v-model="complex.feed.iddomclick">
          </div>
          <div class="form-group">
            <label>{{$t('DomclickPhone')}}</label>
            <input type="text" class="form-control" v-model="complex.feed.feedphones.phonedomclick">
          </div>
          <div class="map-selected-complex">
            <yandex-map-selected :coordsInfo="startCoord" :zoom="zoom" @point-data="fixPoint"></yandex-map-selected>
          </div>
        </div>
      </div>
      <div class="feed-data-wrapper" v-if="complex.feed && (complex.feed.avitolink||complex.feed.cianlink||complex.feed.yandexlink||complex.feed.domclicklink)">
        <div class="feed-data-title"><h4>{{$t('СсылкиНаФиды')}}:</h4></div>
        <div class="feed-data-body">
          <div class="form-group" v-if="complex.feed.avitolink">
            <label>{{$t('Авито')}}</label>
            <div class="feed-url">
              <div class="feed-url__input">
                <input id="urlAttend1" type="text" @click="copyUrl(1)" readonly v-model="complex.feed.avitolink" class="text-input col-12" >
              </div >
              <div class="feed-url__icon">
                <div class="feed-url__icon_container" @click="copyUrl(1)">
                  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0588 20.0022H6.47985C5.9009 20.0022 5.40547 19.7877 4.99354 19.3586C4.5809 18.9289 4.37459 18.4126 4.37459 17.8096V2.46204C4.37459 1.8591 4.5809 1.34277 4.99354 0.913034C5.40547 0.484032 5.9009 0.269531 6.47985 0.269531H12.9799C13.2606 0.269531 13.5283 0.324344 13.783 0.43397C14.037 0.543595 14.2606 0.698899 14.4535 0.899879L19.5588 6.21672C19.7518 6.4177 19.9009 6.65048 20.0062 6.91504C20.1114 7.18033 20.1641 7.45915 20.1641 7.75148V17.8096C20.1641 18.4126 19.9581 18.9289 19.5462 19.3586C19.1335 19.7877 18.6377 20.0022 18.0588 20.0022ZM12.7956 2.46204H6.47985V17.8096H18.0588V7.94333H13.8483C13.55 7.94333 13.3002 7.83845 13.0988 7.6287C12.8967 7.41822 12.7956 7.15768 12.7956 6.84707V2.46204ZM2.26933 24.3872C1.69038 24.3872 1.19494 24.1727 0.78301 23.7437C0.370378 23.3139 0.164062 22.7976 0.164062 22.1947V7.94333C0.164062 7.63272 0.265115 7.37218 0.467221 7.1617C0.668624 6.95195 0.918448 6.84707 1.21669 6.84707C1.51494 6.84707 1.76512 6.95195 1.96722 7.1617C2.16862 7.37218 2.26933 7.63272 2.26933 7.94333V22.1947H12.7956C13.0939 22.1947 13.3441 22.2999 13.5462 22.5104C13.7476 22.7201 13.8483 22.9803 13.8483 23.2909C13.8483 23.6015 13.7476 23.8617 13.5462 24.0715C13.3441 24.2819 13.0939 24.3872 12.7956 24.3872H2.26933ZM6.47985 2.46204V7.94333V2.46204V17.8096V2.46204Z" fill="#D9D9D9"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="complex.feed.cianlink">
            <label>{{$t('Циан')}}</label>
            <div class="feed-url">
              <div class="feed-url__input">
                <input id="urlAttend2" type="text" @click="copyUrl(2)" readonly v-model="complex.feed.cianlink" class="text-input col-12" >
              </div >
              <div class="feed-url__icon">
                <div class="feed-url__icon_container" @click="copyUrl(2)">
                  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0588 20.0022H6.47985C5.9009 20.0022 5.40547 19.7877 4.99354 19.3586C4.5809 18.9289 4.37459 18.4126 4.37459 17.8096V2.46204C4.37459 1.8591 4.5809 1.34277 4.99354 0.913034C5.40547 0.484032 5.9009 0.269531 6.47985 0.269531H12.9799C13.2606 0.269531 13.5283 0.324344 13.783 0.43397C14.037 0.543595 14.2606 0.698899 14.4535 0.899879L19.5588 6.21672C19.7518 6.4177 19.9009 6.65048 20.0062 6.91504C20.1114 7.18033 20.1641 7.45915 20.1641 7.75148V17.8096C20.1641 18.4126 19.9581 18.9289 19.5462 19.3586C19.1335 19.7877 18.6377 20.0022 18.0588 20.0022ZM12.7956 2.46204H6.47985V17.8096H18.0588V7.94333H13.8483C13.55 7.94333 13.3002 7.83845 13.0988 7.6287C12.8967 7.41822 12.7956 7.15768 12.7956 6.84707V2.46204ZM2.26933 24.3872C1.69038 24.3872 1.19494 24.1727 0.78301 23.7437C0.370378 23.3139 0.164062 22.7976 0.164062 22.1947V7.94333C0.164062 7.63272 0.265115 7.37218 0.467221 7.1617C0.668624 6.95195 0.918448 6.84707 1.21669 6.84707C1.51494 6.84707 1.76512 6.95195 1.96722 7.1617C2.16862 7.37218 2.26933 7.63272 2.26933 7.94333V22.1947H12.7956C13.0939 22.1947 13.3441 22.2999 13.5462 22.5104C13.7476 22.7201 13.8483 22.9803 13.8483 23.2909C13.8483 23.6015 13.7476 23.8617 13.5462 24.0715C13.3441 24.2819 13.0939 24.3872 12.7956 24.3872H2.26933ZM6.47985 2.46204V7.94333V2.46204V17.8096V2.46204Z" fill="#D9D9D9"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="complex.feed.yandexlink">
            <label>{{$t('Яндекс')}}</label>
            <div class="feed-url">
              <div class="feed-url__input">
                <input id="urlAttend3" type="text" @click="copyUrl(3)" readonly v-model="complex.feed.yandexlink" class="text-input col-12" >
              </div >
              <div class="feed-url__icon">
                <div class="feed-url__icon_container" @click="copyUrl(3)">
                  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0588 20.0022H6.47985C5.9009 20.0022 5.40547 19.7877 4.99354 19.3586C4.5809 18.9289 4.37459 18.4126 4.37459 17.8096V2.46204C4.37459 1.8591 4.5809 1.34277 4.99354 0.913034C5.40547 0.484032 5.9009 0.269531 6.47985 0.269531H12.9799C13.2606 0.269531 13.5283 0.324344 13.783 0.43397C14.037 0.543595 14.2606 0.698899 14.4535 0.899879L19.5588 6.21672C19.7518 6.4177 19.9009 6.65048 20.0062 6.91504C20.1114 7.18033 20.1641 7.45915 20.1641 7.75148V17.8096C20.1641 18.4126 19.9581 18.9289 19.5462 19.3586C19.1335 19.7877 18.6377 20.0022 18.0588 20.0022ZM12.7956 2.46204H6.47985V17.8096H18.0588V7.94333H13.8483C13.55 7.94333 13.3002 7.83845 13.0988 7.6287C12.8967 7.41822 12.7956 7.15768 12.7956 6.84707V2.46204ZM2.26933 24.3872C1.69038 24.3872 1.19494 24.1727 0.78301 23.7437C0.370378 23.3139 0.164062 22.7976 0.164062 22.1947V7.94333C0.164062 7.63272 0.265115 7.37218 0.467221 7.1617C0.668624 6.95195 0.918448 6.84707 1.21669 6.84707C1.51494 6.84707 1.76512 6.95195 1.96722 7.1617C2.16862 7.37218 2.26933 7.63272 2.26933 7.94333V22.1947H12.7956C13.0939 22.1947 13.3441 22.2999 13.5462 22.5104C13.7476 22.7201 13.8483 22.9803 13.8483 23.2909C13.8483 23.6015 13.7476 23.8617 13.5462 24.0715C13.3441 24.2819 13.0939 24.3872 12.7956 24.3872H2.26933ZM6.47985 2.46204V7.94333V2.46204V17.8096V2.46204Z" fill="#D9D9D9"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="complex.feed.domclicklink">
            <label>{{$t('Домклик')}}</label>
            <div class="feed-url">
              <div class="feed-url__input">
                <input id="urlAttend4" type="text" @click="copyUrl(4)" readonly v-model="complex.feed.domclicklink" class="text-input col-12" >
              </div >
              <div class="feed-url__icon">
                <div class="feed-url__icon_container" @click="copyUrl(4)">
                  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0588 20.0022H6.47985C5.9009 20.0022 5.40547 19.7877 4.99354 19.3586C4.5809 18.9289 4.37459 18.4126 4.37459 17.8096V2.46204C4.37459 1.8591 4.5809 1.34277 4.99354 0.913034C5.40547 0.484032 5.9009 0.269531 6.47985 0.269531H12.9799C13.2606 0.269531 13.5283 0.324344 13.783 0.43397C14.037 0.543595 14.2606 0.698899 14.4535 0.899879L19.5588 6.21672C19.7518 6.4177 19.9009 6.65048 20.0062 6.91504C20.1114 7.18033 20.1641 7.45915 20.1641 7.75148V17.8096C20.1641 18.4126 19.9581 18.9289 19.5462 19.3586C19.1335 19.7877 18.6377 20.0022 18.0588 20.0022ZM12.7956 2.46204H6.47985V17.8096H18.0588V7.94333H13.8483C13.55 7.94333 13.3002 7.83845 13.0988 7.6287C12.8967 7.41822 12.7956 7.15768 12.7956 6.84707V2.46204ZM2.26933 24.3872C1.69038 24.3872 1.19494 24.1727 0.78301 23.7437C0.370378 23.3139 0.164062 22.7976 0.164062 22.1947V7.94333C0.164062 7.63272 0.265115 7.37218 0.467221 7.1617C0.668624 6.95195 0.918448 6.84707 1.21669 6.84707C1.51494 6.84707 1.76512 6.95195 1.96722 7.1617C2.16862 7.37218 2.26933 7.63272 2.26933 7.94333V22.1947H12.7956C13.0939 22.1947 13.3441 22.2999 13.5462 22.5104C13.7476 22.7201 13.8483 22.9803 13.8483 23.2909C13.8483 23.6015 13.7476 23.8617 13.5462 24.0715C13.3441 24.2819 13.0939 24.3872 12.7956 24.3872H2.26933ZM6.47985 2.46204V7.94333V2.46204V17.8096V2.46204Z" fill="#D9D9D9"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn btn-sm btn-success col-12" @click="saveBuilding">{{ $t('сохранитьИзменения') }}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import YandexMapSelected from "@/components/YandexMapSelected.vue";
import dropdownComponent from "@/components/dropdown.vue";
import AlertModal from "@/components/redesign/alertModal.vue";
import Inputmask from "inputmask";

export default {
  name: "FeedsEditorProject",
  components: {AlertModal, dropdownComponent, YandexMapSelected},
  data(){
    return{
      complex:{},
      ready_quarter_list:[{id:1, text:'1'}, {id:2, text:'2'}, {id:3, text:'3'}, {id:4, text:'4'}],
      startCoord:[],
      zoom: 9,
      visibleSuccessCopy:false
    }
  },
  updated () {
    setTimeout(() => { Inputmask(this.maskPhone).mask(document.getElementsByClassName("feed-phone")) }, 1)
  },
  methods:{
    ...mapMutations({
      setComplexAll: 'editorProjectData/setComplexAll',
    }),
    saveBuilding () {
      var object = JSON.parse(JSON.stringify(this.complex))
      if (object.feed.ready_quarter&&object.feed.ready_quarter.length===0)object.feed.ready_quarter=null
      this.setComplexAll(object)
      try {
        axios.post('/api/post-building?id='+this.$route.params.id, object).then((res)=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    fixPoint(point){
      this.complex.feed.latitude=point[0] ?? ''
      this.complex.feed.longitude=point[1] ?? ''
      this.startCoord=point
    },
    copyUrl(ind) {
      var Url = document.getElementById('urlAttend'+ind); /*GET vuejs el reference here (via $ref) but how?*/
      Url.select();
      document.execCommand("copy");
      this.visibleSuccessCopy=true
    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
    maskPhone() {
      return this.$store.getters.getMaskPhone
    },
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    if(this.complex.feed.latitude&&this.complex.feed.longitude)this.startCoord=[this.complex.feed.latitude, this.complex.feed.longitude]
  }
}
</script>

<style scoped lang="scss">

</style>