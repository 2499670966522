<template>
  <div class="yandex-map-multiple">
    <div class="map">
      <yandex-map
          v-model="map"
          :settings="{
          mode: 'vector',
          location: {
            center: [centerCoords[1], centerCoords[0]],
            zoom: zoom,
            },
          }"
      >
        <yandex-map-default-scheme-layer :settings="{customization: customization_map}"/>
        <yandex-map-default-features-layer/>
        <yandex-map-feature
            v-for="(feature, index) in list_latlong"
            :key="index + '_object'"
            :settings="{
              geometry: {
                type: 'Polygon',
                coordinates: feature.coords
              },
              style: {
                fill: get_options(feature.text_status),
                stroke: [{width: 0.5, opacity: 0.3}],
                fillOpacity: 0.3
              }
            }"
        />
<!--        Если понадобится кластеризация-->
<!--        <yandex-map-clusterer-->
<!--            v-model="clusterer"-->
<!--            zoom-on-cluster-click-->
<!--            @trueBounds="openCluster"-->
<!--        >-->
          <yandex-map-marker
              v-for="(marker, index) in markers_latlong"
              :key="index+ '_marker'"
              :settings="{
               coordinates: marker.coords,
               properties: {
                 ...marker,
                 hint: marker.name,
                 index
               },
               draggable: true,
               onDragEnd: (coords) => onMarkerDragEnd(coords, index)
          }"
          >
            <div class="marker">
              <img v-if="marker.icon" width="36" height="36" :src="marker.icon" alt="">
              <svg v-else width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill="#848AFE"/>
                <path d="M13 20V26C13 26.2833 12.9042 26.5208 12.7125 26.7125C12.5208 26.9042 12.2833 27 12 27C11.7167 27 11.4792 26.9042 11.2875 26.7125C11.0958 26.5208 11 26.2833 11 26V11C11 10.7167 11.0958 10.4792 11.2875 10.2875C11.4792 10.0958 11.7167 10 12 10H19.175C19.4083 10 19.6167 10.075 19.8 10.225C19.9833 10.375 20.1 10.5667 20.15 10.8L20.4 12H25C25.2833 12 25.5208 12.0958 25.7125 12.2875C25.9042 12.4792 26 12.7167 26 13V21C26 21.2833 25.9042 21.5208 25.7125 21.7125C25.5208 21.9042 25.2833 22 25 22H19.825C19.5917 22 19.3833 21.925 19.2 21.775C19.0167 21.625 18.9 21.4333 18.85 21.2L18.6 20H13ZM20.65 20H24V14H19.575C19.3417 14 19.1333 13.925 18.95 13.775C18.7667 13.625 18.65 13.4333 18.6 13.2L18.35 12H13V18H19.425C19.6583 18 19.8667 18.075 20.05 18.225C20.2333 18.375 20.35 18.5667 20.4 18.8L20.65 20Z" fill="white"/>
              </svg>


            </div>
          </yandex-map-marker>
<!--          <template #cluster="{ length }">-->
<!--            <div-->
<!--                class="circle"-->
<!--            >-->
<!--              <div class="circle-content">-->
<!--                <span class="circle-text">-->
<!--                  {{ length }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </yandex-map-clusterer>-->

        <yandex-map-hint hint-property="hint">
          <template #default="{ content }">
            <div class="myHint_YandexMapsMultiple">
              <svg class="myHint_YandexMapsMultiple_svg" width="20" height="8" viewBox="0 0 20 8" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C13 0 15.9999 8 20 8H0C3.9749 8 7 0 10 0Z"/>
              </svg>
              {{content}}
            </div>
          </template>
        </yandex-map-hint>
        <yandex-map-listener
            :settings="{ onClick: onClick }"
        />

      </yandex-map>
    </div>

    <div class="custom_baloon" v-if="currentMarker !== null">
      <div class="close" @click="currentMarker = null; editPointOne = false; editPointTwo = false; addUrl = false">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.85 12L4 11.15L7.15 8L4 4.85L4.85 4L8 7.15L11.15 4L12 4.85L8.85 8L12 11.15L11.15 12L8 8.85L4.85 12Z" fill="#3C3D4A"/>
        </svg>
      </div>
      <h6>Marker: {{markers[currentMarker].name}}</h6>
      <div class="coords">
        <div class="edit-coords">
          <svg @click="editPointOne = true" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83333 22.1667H7.49583L18.9 10.7626L17.2375 9.10006L5.83333 20.5042V22.1667ZM4.66667 24.5001C4.33611 24.5001 4.05903 24.3883 3.83542 24.1646C3.61181 23.941 3.5 23.6639 3.5 23.3334V20.5042C3.5 20.1931 3.55833 19.8966 3.675 19.6146C3.79167 19.3327 3.95694 19.0848 4.17083 18.8709L18.9 4.17089C19.1333 3.95701 19.391 3.79173 19.6729 3.67506C19.9549 3.55839 20.2514 3.50006 20.5625 3.50006C20.8736 3.50006 21.175 3.55839 21.4667 3.67506C21.7583 3.79173 22.0111 3.96673 22.225 4.20006L23.8292 5.83339C24.0625 6.04728 24.2326 6.30006 24.3396 6.59173C24.4465 6.88339 24.5 7.17506 24.5 7.46673C24.5 7.77784 24.4465 8.07437 24.3396 8.35631C24.2326 8.63826 24.0625 8.89589 23.8292 9.12923L9.12917 23.8292C8.91528 24.0431 8.66736 24.2084 8.38542 24.3251C8.10347 24.4417 7.80694 24.5001 7.49583 24.5001H4.66667ZM18.0542 9.94589L17.2375 9.10006L18.9 10.7626L18.0542 9.94589Z" fill="#3C3D48"/>
          </svg>
          <input autofocus @focusout="editPointOne = false" :value="markers[currentMarker].coords[0]" @change="change_coords_one" type="text" placeholder="Точка" class="form-control" v-if="editPointOne"/>
          <p v-else>{{markers[currentMarker].coords[0]}}</p>
        </div>

        <div class="edit-coords">
          <svg @click="editPointTwo = true" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83333 22.1667H7.49583L18.9 10.7626L17.2375 9.10006L5.83333 20.5042V22.1667ZM4.66667 24.5001C4.33611 24.5001 4.05903 24.3883 3.83542 24.1646C3.61181 23.941 3.5 23.6639 3.5 23.3334V20.5042C3.5 20.1931 3.55833 19.8966 3.675 19.6146C3.79167 19.3327 3.95694 19.0848 4.17083 18.8709L18.9 4.17089C19.1333 3.95701 19.391 3.79173 19.6729 3.67506C19.9549 3.55839 20.2514 3.50006 20.5625 3.50006C20.8736 3.50006 21.175 3.55839 21.4667 3.67506C21.7583 3.79173 22.0111 3.96673 22.225 4.20006L23.8292 5.83339C24.0625 6.04728 24.2326 6.30006 24.3396 6.59173C24.4465 6.88339 24.5 7.17506 24.5 7.46673C24.5 7.77784 24.4465 8.07437 24.3396 8.35631C24.2326 8.63826 24.0625 8.89589 23.8292 9.12923L9.12917 23.8292C8.91528 24.0431 8.66736 24.2084 8.38542 24.3251C8.10347 24.4417 7.80694 24.5001 7.49583 24.5001H4.66667ZM18.0542 9.94589L17.2375 9.10006L18.9 10.7626L18.0542 9.94589Z" fill="#3C3D48"/>
          </svg>

          <input autofocus @focusout="editPointTwo = false"  :value="markers[currentMarker].coords[1]" @change="change_coords_two" type="text" placeholder="Точка" class="form-control" v-if="editPointTwo"/>
          <p  v-else>{{markers[currentMarker].coords[1]}}</p>
        </div>

      </div>

      <div>
        <input type="text" placeholder="Название" v-model="markers[currentMarker].name" class="form-control input_name">
        <div class="icon_add_button">
          <label v-show="!markers[currentMarker].icon"  for="icon" class="btn btn-info btn-sm">Добавить иконку</label>
          <input id="icon" type="file" ref="icon" @change="handleFileUpload" class="form-control hidden">
        </div>
        <div id="icon_marker" class="file" v-show="markers[currentMarker].icon">
          <div class="delete" @click="markers[currentMarker].icon = null">❌</div><img :src="markers[currentMarker].icon" alt="">
        </div>
        <div class="icon_add_button">
          <input v-if="addUrl" class="form-control" type="text" v-model="url" placeholder="Ссылка" @input="change_url">
          <label v-else @click="addUrl = true"  class="btn btn-info btn-sm">По ссылке</label>
        </div>
        <div class="icon_add_button">
          <label @click="delete_marker"  for="marker" class="btn btn-danger btn-sm">Удалить маркер</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  YandexMap, YandexMapClusterer,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapFeature, YandexMapHint, YandexMapListener,
  YandexMapMarker
} from 'vue-yandex-maps'
import {shallowRef} from "vue";
import customization_map from "@/mixins/customization_map";
export default {
  components: {
    YandexMapClusterer,
    YandexMapListener,
    YandexMapHint,
    YandexMapFeature, YandexMapMarker, YandexMap, YandexMapDefaultFeaturesLayer, YandexMapDefaultSchemeLayer},
  name: "YandexMapMultiple",
  mixins: [customization_map],
  props: {
    markers: {
      type: Array,
      default: [],
      required: false
    },
    zoom: {
      type: Number,
      default: 12,
      required: true
    },
    list: {
      type: Array
    }
  },
  data() {
    return {
      centerCoords:[45.0448, 38.976],
      map: shallowRef(null),
      currentMarker: null,
      editPointOne: false,
      editPointTwo: false,
      url: null,
      addUrl: false,
      clusterer: shallowRef(null)
    };
  },
  computed: {
    markers_latlong() {
      return this.markers.map(el => {
        return {
          ...el,
          coords: [el.coords[1], el.coords[0]]
        }
      })
    },
    list_latlong() {
      return this.list.reduce((acc,el) => {
        if(el.coords && el.coords.length) {
          return [
            ...acc,
            {
              ...el,
              coords: el.coords.map(el2 => el2.map(e => [e[1], e[0]]))
            }
          ]
        }
        return acc
      }, [])
    }
  },
  methods: {
    openCluster(data, e) {
      console.log(data)
      console.log(e)
    },
    change_url(e) {
      const urlParams = new URLSearchParams(e.target.value)
      const data = urlParams.get('whatshere[point]')
      if(data) {
        const [latitude,longitude] = data.split(',')
        if(!isNaN(parseFloat(longitude)) && !isNaN(parseFloat(latitude))) {
          this.markers[this.currentMarker].coords = [longitude,latitude]
          this.currentPoint()
          this.addUrl = false
        }
      }
      this.url = null
    },
    get_options(status) {
      let color = '#6699ff'
      if(this.$store.getters.getStatusesBooked.includes(status)) color = '#ff9d57'
      else if (this.$store.getters.getStatusesSales.includes(status)) color = '#b9bac5'
      else if(this.$store.getters.getStatusesReserved.includes(status) &&
          (this.$store.getters.getProfile.role === 'admin' || this.$store.getters.getProfile.role === 'bitrix')
      ) color = '#FBD565'
      else if (this.$store.getters.getStatusesReserved.includes(status)) color = '#ff9d57'
      else if(status === 'Свободно')  color = '#58c095'

      return color
    },
    change_coords_one(e) {
      this.markers[this.currentMarker].coords = [parseFloat(e.target.value), this.markers[this.currentMarker].coords[1]];
      this.currentPoint()
    },
    change_coords_two(e) {
      this.markers[this.currentMarker].coords = [this.markers[this.currentMarker].coords[0], parseFloat(e.target.value)]
      this.currentPoint()
    },
    currentPoint() {
      if (this.currentMarker !== null) {
        this.map.setLocation({
          center: [this.markers[this.currentMarker].coords[1], this.markers[this.currentMarker].coords[0]],
          duration: 1000 * 1.5,
          timingFunction: 'ease-in'
        })
      }
    },
    onMarkerDragEnd(coords, index) {
      coords = [coords[1], coords[0]]
      this.markers[index].coords = coords
    },
    onClick(object, e) {
      if(!object?.entity.properties?.index && e.type !== 'marker') {
        this.markers.push({
          coords: [e.coordinates[1], e.coordinates[0]],
          name: null,
          icon: null
        })
      }
      else {
        this.currentMarker = object.entity.properties.index
      }
    },
    delete_marker() {
      const currentMarker = this.currentMarker
      this.currentMarker = null
      this.markers.splice(currentMarker, 1)
    },
    handleFileUpload () {
      var file = this.$refs['icon'].files[0]
      var reader = new FileReader()
      const self = this
      reader.fileName = file.name
      reader.onload = function (e) {
        $.post('https://cdn.itrielt.ru/upload/file', {
          file: {
            name: e.target.fileName,
            data: e.target.result
          }
        }).then(data => {
          if (data.status === 'success') {
            console.log(self.markers[self.currentMarker])
            self.markers[self.currentMarker].icon = data.url
          }
        })
      }
      reader.readAsDataURL(file)
    }
  },
  mounted() {
    if(this.markers.length) {
      this.centerCoords = this.markers[0].coords
    }
  },
  watch: {

    markers: {
      handler(after) {
        this.$emit('save-markers', after)
      },
      deep: true,
    }
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
.circle {
  position: relative;

  width: 40px;
  height: 40px;

  color: #3a523d;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  transform: translate(-50%, -50%);

  .circle-content {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 70%;
    height: 70%;

    border-radius: 50%;
    background-color: currentColor;

    transform: translate3d(-50%, -50%, 0);
  }

  .circle-text {
    font-size: 0.9em;
    color: #fff;
  }
}
.marker {
  >* {
    width: 25px;
    height: 25px;
  }
/*  &.default_marker {
    position: relative;
    width: 20px;
    height: 20px;
    background: #ff0000;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 20px;
  }*/
}
.myHint_YandexMapsMultiple {
  transform: translate(-14px, 25px);
  color: var(--colorTextPrimary);
  border-radius: 8px;
  box-shadow: 0 5px 25px #2227340D;
  border: none;
  background-color: #FFFFFF;
  display: inline-block;
  padding: 7px;
  height: fit-content;
  max-width: 160px;
  position: absolute;
  width: fit-content;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  vertical-align: middle;
  .myHint_YandexMapsMultiple_svg {
    position: absolute;
    top: -8px;
    fill: white;
  }
}
.ymap-container {
  height: 100%;
}
.yandex-map-multiple {
  position: relative;
  .map {
    max-height: 100%;
    height: 720px;
  }
  @media (width < 1090px) {
    .map {
      max-height: 100%;
      height: 700px;
    }
  }
  .custom_baloon {
    top: 10px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--colorFocused);
    right: 10px;
    width: 170px;
    min-height: 200px;
    background-color: var(--colorBackgroundContrastInvariably);
    position: absolute;
    .coords {
      p {
        margin: 0;
      }
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 10px;
      .edit-coords {
        margin-left: 10px;
        position: relative;
        input {
          padding:5px ;
          height: 28px;
          font-size: 12px;
        }
        > svg {
          cursor: pointer;
          width: 17px;
          height: 17px;
          left: -17px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }
    .close {
      position: absolute;
      cursor: pointer;
      top: 2px;
      right: 5px;
    }
    .icon_add_button {
      margin-top: 5px;
      label {
        margin: 0;
        width: 100%;
        font-size: 10px;
        padding: 4px;
      }
    }
    #icon_marker {

      max-width: 80%;
      max-height: 100%;
      margin: 10px auto;

      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
.delete {
  margin-top: -20px;
  margin-right: -20px;
  font-size: 13px;
}
</style>