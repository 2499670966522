<template>
  <div class="card-body">
    <modal-container
        v-if="visibleModal"
        :title="$t('Наценка')"
        :confirmText="$t('сохранить')"
        :width="'700px'"
        @confirm-option="saveData"
        @close-modal="closeModalEditor"
    >
      <div class="editor-modal-blocks-project">
        <div class="form-group">
          <label for="typeObject">{{ $t('Помещение') }}</label>
          <dropdown-component :elements="['Квартира', 'Апартаменты', 'Коммерция', 'Загородные дома', 'Парковки', 'Кладовки', 'Подсобное помещение']" :none-hide="true" :open="'bottom'" :multiple="false" v-model="changeData.typeObject" :value="changeData.typeObject"></dropdown-component>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="sum_square">{{ $t('стоимостьНаценкиВРубляхЗаМ2') }}</label>
              <input type="number" class="form-control" id="sum_square" disabled readonly v-if="changeData.sum_all || changeData.percent_all" v-model="changeData.sum_square">
              <input type="number" class="form-control" id="sum_square" v-else v-model="changeData.sum_square">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="percent_square">{{ $t('стоимостьНаценкиВПроцентахЗаМ2') }}</label>
              <input type="number" class="form-control" id="percent_square" disabled readonly v-if="changeData.sum_all || changeData.percent_all" v-model="changeData.percent_square">
              <input type="number" class="form-control" id="percent_square" v-else v-model="changeData.percent_square">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="sum_all">{{ $t('стоимостьНаценкиВРубляхОтОбщейСтоимостиОбъекта') }}</label>
              <input type="number" class="form-control" id="sum_all" disabled readonly v-if="changeData.sum_square || changeData.percent_square" v-model="changeData.sum_all">
              <input type="number" class="form-control" id="sum_all" v-else v-model="changeData.sum_all">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="percent_all">{{ $t('стоимостьНаценкиВПроцентахОтОбщейСтоимостиОбъекта') }}</label>
              <input type="number" class="form-control" id="percent_all" disabled readonly v-if="changeData.sum_square || changeData.percent_square" v-model="changeData.percent_all">
              <input type="number" class="form-control" id="percent_all" v-else v-model="changeData.percent_all">
            </div>
          </div>
          <div class="form-group">
            <label for="price_square">{{ $t('стоимостьЗаКвадратныйМетр') }}</label>
            <input type="number" class="form-control" id="price_square" v-model="changeData.price_square">
          </div>
          <div class="form-group">
            <label for="first">{{ $t('СуммаБпв') }}</label>
            <input type="number" class="form-control" id="first" v-model="changeData.first">
          </div>
          <div class="form-group">
            <label for="first_percent">% {{ $t('удорожанияКПрайсу') }}</label>
            <input type="number" class="form-control" id="first_percent" v-model="changeData.first_percent">
          </div>
          <div class="form-group">
            <label for="min_metr">{{ $t('МинимальнаяСуммаЗаКвадратныйМетр') }}</label>
            <input type="number" class="form-control" id="min_metr" v-model="changeData.min_metr">
          </div>
          <div class="form-group">
            <label for="nalog">24% {{ $t('Налог') }}</label>
            <select name="balcony" class="form-control" id="nalog" v-model="changeData.nalog">
              <option value="0">{{ $t('НеПрименяется') }}</option>
              <option value="1">{{ $t('Применяется') }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sum_jku">{{ $t('жкуЗаКвадратныйМетр') }}</label>
            <input type="number" class="form-control" id="sum_jku" v-model="changeData.sum_jku">
          </div>
          <div class="form-group">
            <label for="sum_nalog">{{ $t('НалогЗаКвадратныйМетр') }}</label>
            <input type="number" class="form-control" id="sum_nalog" v-model="changeData.sum_nalog">
          </div>
          <div class="form-group">
            <label for="sum_credit">{{ $t('МинимальнаяСуммаКредита') }}</label>
            <input type="number" class="form-control" id="sum_credit" v-model="changeData.sum_credit">
          </div>
          <div class="form-group">
            <label for="bpv">{{ $t('бпв') }}</label>
            <select name="balcony" class="form-control" id="bpv" v-model="changeData.bpv">
              <option value="0">{{ $t('НеПрименяется') }}</option>
              <option value="1">{{ $t('Применяется') }}</option>
            </select>
          </div>
        </div>
      </div>
    </modal-container>
    <div class="card-body">
      <div class="container p-30" id="wrapper-row-admin">
        <div class="row">
          <div class="col-md-12 main-datatable">
            <button class="btn btn-sm btn-success d-inline-block mb-2 col-12" @click="createMarkup()">{{ $t('Добавить') }}</button>
            <div class="card_body">
              <div class="overflow-x">
                <table id="filtertable" class="table cust-datatable dataTable no-footer">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('Литер') }}</th>
                    <th>{{ $t('Помещение') }}</th>
                    <th>{{ $t('Этаж') }}</th>
                    <th>{{ $t('Площадь') }}</th>
                    <th>{{ $t('Комнат') }}</th>
                    <th>{{ $t('Балкон') }}</th>
                    <th>{{ $t('Наценка') }}</th>
                    <th>{{ $t('СтоимостьЗаМ2') }}</th>
                    <th>{{ $t('МинСтоимостьЗаМ2') }}</th>
                    <th>{{ $t('бпв') }}</th>
                    <th>+% {{ $t('кБпв') }}</th>
                    <th>{{ $t('Налог') }} 24%</th>
                    <th>{{ $t('жку') }}</th>
                    <th>{{ $t('Налог') }}</th>
                    <th>{{ $t('КредитОт') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="stock in stocks" :key="stock.id">
                    <td>{{stock.id}}</td>
                    <td><slot v-if="complex.objects && complex.objects[stock.development_id]">{{complex.objects[stock.development_id]}}</slot><slot v-else>{{stock.development_id}}</slot></td>
                    <td>{{stock.typeObject}}</td>
                    <td><slot v-if="stock.floor_min">{{stock.floor_min}}</slot><slot v-if="stock.floor_max"> - {{stock.floor_max}}</slot></td>
                    <td><slot v-if="stock.square_min">{{stock.square_min}}</slot><slot v-if="stock.square_max"> - {{stock.square_max}}</slot></td>
                    <td><slot v-if="stock.room_min">{{stock.room_min}}</slot><slot v-if="stock.room_max"> - {{stock.room_max}}</slot></td>
                    <td>{{parseInt(stock.balcony)===1?'Да':'Нет'}}</td>
                    <td @click="showModal(stock)">
                      <slot v-if="stock.sum_square && stock.percent_square">{{priceFormat(stock.sum_square)}}/{{ $t('м²') }} <br> {{stock.percent_square}}%/{{ $t('м²') }}</slot>
                      <slot v-else-if="stock.sum_square">{{priceFormat(stock.sum_square)}}/{{ $t('м²') }}</slot>
                      <slot v-else-if="stock.percent_square">{{stock.percent_square}}%/{{ $t('м²') }}</slot>
                      <slot v-else-if="stock.sum_all">{{priceFormat(stock.sum_all)}}</slot>
                      <slot v-else-if="stock.percent_all">{{stock.percent_all}}%</slot>
                      <div v-else class="btn btn-sm btn-info">{{ $t('назначить') }}</div>
                    </td>
                    <td><slot v-if="stock.price_square">{{priceFormat(stock.price_square)}}</slot></td>
                    <td><slot v-if="stock.min_metr">{{priceFormat(stock.min_metr)}}</slot></td>
                    <td><slot v-if="stock.bpv === 1">{{ $t('Применяется') }}</slot><slot v-if="stock.bpv === 0">{{ $t('НеПрименяется') }}</slot><slot v-if="stock.first"><br>{{priceFormat(stock.first)}}</slot></td>
                    <td><slot v-if="stock.first_percent">{{(stock.first_percent)}}%</slot></td>
                    <td><slot v-if="stock.nalog">{{ $t('Применяется') }}</slot></td>
                    <td><slot v-if="stock.sum_jku">{{priceFormat(stock.sum_jku)}}</slot></td>
                    <td><slot v-if="stock.sum_nalog">{{priceFormat(stock.sum_nalog)}}</slot></td>
                    <td><slot v-if="stock.sum_credit">{{priceFormat(stock.sum_credit)}}</slot></td>
                    <td><router-link tag="button" :to="'/markup/'+stock.id" class="btn btn-sm btn-warning">{{$t('редактировать')}}</router-link><button class="btn btn-sm btn-danger m-l-10" @click="deleteMarkup(stock.id)">{{ $t('удалить') }}</button></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'datatables.net-bs5'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import $ from 'jquery'
import {mapGetters, mapMutations} from "vuex";
import priceFormat from "@/mixins/priceFormat";
import axios from "axios";
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";

export default {
  name: "MarkupsEditorProject",
  components: {ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data(){
    return{
      complex:{},
      table: null,
      stocks: [],
      changeData: {},
      visibleModal:false
    }
  },
  methods:{
    loadMarkups() {
      if(this.table !== null) this.table.destroy()
      axios.get('/api/get-markups?id='+this.$route.params.id).then(response => {
        this.stocks = response.data
        setTimeout(() => {
          $.fn.dataTable.ext.errMode = 'none';
          this.table = $('#filtertable').DataTable({
            stateSave: true,
            destroy: true,
            paging: true,
            pageLength: 25,
            language: {
              'decimal': '',
              'emptyTable': `${this.$i18n.t('ДанныхНет')}`,
              'info': `${this.$i18n.t('ПоказаноС')} _START_ ${this.$i18n.t('по')} _END_ ${this.$i18n.t('из')} _TOTAL_ ${this.$i18n.t('строк')}`,
              'infoEmpty': '',
              'infoFiltered': `(${this.$i18n.t('найденоИзИзначальных')} _MAX_ строк)`,
              'infoPostFix': '',
              'thousands': ',',
              'lengthMenu': `${this.$i18n.t('ПоказатьПо')} _MENU_`,
              'loadingRecords': `${this.$i18n.t('Загрузка')}...`,
              'processing': `${this.$i18n.t('Поиск')}...`,
              'search': `${this.$i18n.t('Поиск')}:`,
              'zeroRecords': `${this.$i18n.t('СовпаденийНеНайдено')}`,
              'paginate': {
                'first': `${this.$i18n.t('Первая')}`,
                'last': `${this.$i18n.t('Последняя')}`,
                'next': `${this.$i18n.t('Вперед')}`,
                'previous': `${this.$i18n.t('Назад')}`
              },
              'aria': {
                'sortAscending': `: ${this.$i18n.t('сортировкаПоВозрастанию')}`,
                'sortDescending': `: ${this.$i18n.t('сортировкаПоУбыванию')}`
              }
            },
            order: [[ 0, "desc" ]],
            'columns' : [
              {'visible' : false },
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            ]
          })
        }, 100)
      })
    },
    deleteMarkup(id) {
      axios.post('/api/delete-markup?id='+id)
    },
    createMarkup() {
      axios.post('/api/create-markup?id='+this.$route.params.id).then((res)=>{
        window.open('/markup/' + res.data.id, "_self")
      })
    },
    saveData () {
      var object = JSON.parse(JSON.stringify(this.changeData))
      axios.post('/api/post-markup?id='+this.changeData.id, object).then(()=>{
        this.closeModalEditor()
      })
    },
    closeModalEditor(){
      this.visibleModal=false
      this.changeData = {}
    },
    showModal (data) {
      if(data.id){
        this.changeData = data
        this.visibleModal = true
      }
    },
  },
  computed:{
    ...mapGetters({
      storeComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    storeComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.storeComplex))
    this.loadMarkups()
  }
}
</script>

<style scoped lang="scss">

</style>