<template>
  <div class="card-body" v-if="!load">
    <yandex-map-multiple :list="list" :markers="complex.markers" :zoom="zoom" @save-markers="fixMarker"></yandex-map-multiple>
    <div class="btn btn-sm btn-success col-12" @click="saveBuilding">{{ $t('сохранитьИзменения') }}</div>
  </div>
  <div class="editor-map-loader" v-else>
    <loader :text="$t('ДанныеЗагружаются')"></loader>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import YandexMapMultiple from "@/components/YandexMapMultiple.vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";

export default {
  name: "MapEditorProject",
  components: {Loader, YandexMapMultiple},
  data(){
    return{
      complex:{},
      zoom: 9,
      list: [],
      load:true
    }
  },
  methods:{
    ...mapMutations({
      setComplexAll: 'editorProjectData/setComplexAll',
    }),
    saveBuilding () {
      var object = JSON.parse(JSON.stringify(this.complex))
      this.setComplexAll(object)
      try {
        axios.post('/api/post-building?id='+this.$route.params.id, object).then((res)=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    fixMarker(data) {
      this.complex.markers = data
    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    axios.get('/api/get-kps').then(res => {
      this.list = res.data
      this.load=false
    })
  }
}
</script>

<style scoped lang="scss">
.editor-map-loader{
  padding: 20px 0;
}
</style>