<template>
  <div class="card-body">
    <div class="form-group">
      <label>{{ $t('НазваниеЖк') }}</label>
      <input type="text" class="form-control"  v-model="complex.name">
    </div>
    <div class="form-group">
      <label>{{$t('ТипПродажи')}}</label>
      <dropdown-component :multiple="false" :elements="types_of_sales" v-model="complex.type_of_sale_id"></dropdown-component>
    </div>
    <div class="form-group">
      <label for="credit">{{$t('ИпотекаБезПВМаксСуммаКредита')}}</label>
      <input type="text" class="form-control" id="credit" v-model="complex.credit">
    </div>
    <div class="form-group">
      <label for="max_discount">{{$t('МаксимальнаяСкидка')}}, %</label>
      <input type="text" class="form-control" id="max_discount" v-model="complex.max_discount">
    </div>
    <div class="form-group">
      <label>{{$t('СсылкаНаПанораму')}}</label>
      <input type="text" :placeholder="$t('ВведитеСсылку')" class="form-control"  v-model="complex.link_panorama">
    </div>
    <div class="form-group">
      <label>{{$t('СсылкаНаВидео')}}</label>
      <input type="text" :placeholder="$t('ВведитеСсылку')" class="form-control"  v-model="complex.link_general_video">
    </div>
    <div class="form-group">
      <label>{{$t('СсылкаНаХодСтроительства')}}</label>
      <input type="text" :placeholder="$t('ВведитеСсылку')" class="form-control"  v-model="complex.link_building">
    </div>
    <div class="form-group" v-if="complex.desc1">
      <label for="desc1">{{ $t('ОписаниеЖкСтарое') }}</label>
      <textarea class="form-control" id="desc1" rows="10" disabled readonly v-model="complex.desc1"></textarea>
    </div>
    <div class="form-group">
      <label for="desc5">{{ $t('ОписаниеЖк') }} <span :class="(complex.desc5||'').length === 0 ? 'bg-info text-white p-1' : ((complex.desc5||'').length < 500 ? 'bg-warning text-white p-1' : ((complex.desc5||'').length <= 1000 ? 'bg-success text-white p-1' : 'bg-danger text-white p-1'))">{{ (complex.desc5||'').length }} / 1000</span></label>
      <textarea maxlength="1000" class="form-control" id="desc5" rows="3" v-model="complex.desc5"></textarea>
    </div>
    <div class="form-group">
      <label for="day">{{$t('СрокФиксации')}}</label>
      <input type="text" class="form-control" id="day" v-model="complex.day">
    </div>
    <div class="form-group">
      <label for="logo">{{$t('ЛоготипОбъекта')}}</label>
      <label for="logo" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <input id="logo"  type="file" ref="logo" v-on:change="handleFileUploads('logo')" class="form-control hidden">
      <div class="files" v-if="files.logo">
        <div class="file"><div class="delete" @click="files.logo = null">❌</div><img :src="files.logo" alt=""></div>
      </div>
    </div>
    <div class="form-group">
      <label for="ImageInfrastructure">{{$t('ИзображениеИнфраструктуры')}}</label>
      <label for="ImageInfrastructure" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <input id="ImageInfrastructure"  type="file" ref="ImageInfrastructure" v-on:change="handleFileUploads('ImageInfrastructure')" class="form-control hidden">
      <div class="files" v-if="files.ImageInfrastructure">
        <div class="file-infrastructure">
          <div class="delete" @click="files.ImageInfrastructure = null">❌</div><img :src="files.ImageInfrastructure" alt="">
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="img6">{{ $t('СлайдерФотографий') }}</label>
      <label for="img6" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <input id="img6"  type="file" ref="img6" v-on:change="handleFileUploads('img6')" class="form-control hidden">
      <div class="files" v-if="files.img6.length">
        <div class="file" v-for="(file, index) in files.img6" :key="file"><div class="delete" @click="deleteFile('img6', index)">❌</div><img :src="file" alt=""></div>
      </div>
    </div>
    <div class="form-group">
      <label for="img4">{{ $t('ГенпланФон') }}</label>
      <label for="img4" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <input id="img4"  type="file" ref="img4" v-on:change="handleFileUploads('img4')" class="form-control hidden">
      <div class="files" v-if="files.img4.length">
        <div class="file" v-for="(file, index) in files.img4" :key="file"><div class="delete" @click="deleteFile('img4', index)">❌</div><img :src="file" alt=""></div>
      </div>
    </div>
    <div class="form-group">
      <label for="desc4">{{ $t('ГенПланSvg') }}</label>
      <textarea class="form-control" id="desc4" rows="3" v-model="complex.desc4"></textarea>
    </div>
    <div class="form-group">
      <label for="img7">{{ $t('ПодвалЖкPdfФон') }}</label>
      <label for="img7" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <input id="img7"  type="file" ref="img7" v-on:change="handleFileUploads('img7')" class="form-control hidden">
      <div class="files" v-if="files.img7.length">
        <div class="file" v-for="(file, index) in files.img7" :key="file"><div class="delete" @click="deleteFile('img7', index)">❌</div><img :src="file" alt=""></div>
      </div>
    </div>
    <div class="form-group">
      <label for="tabs">{{ $t('Теги') }}</label>
      <tags-component :title="false" :tags="tags"/>
    </div>
    <div class="form-group">
      <label for="tabs">{{$t('ДопОписание')}}</label>
      <descriptions-complex  :descriptions="descriptions"/>
    </div>
    <div class="form-group">
      <label for="tabs">{{$t('ПолезныеСсылки')}}</label>
      <useful-links-complex :useful-links="useful_links"/>
    </div>

    <div class="btn btn-sm btn-success col-12" @click="saveComplex">{{ $t('сохранитьИзменения') }}</div>
  </div>
</template>

<script>
import UsefulLinksComplex from "@/components/usefulLinksComplex.vue";
import TagsComponent from "@/components/tagsComponent.vue";
import DescriptionsComplex from "@/components/descriptionsComplex.vue";
import dropdownComponent from "@/components/dropdown.vue";
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import Vue from "vue";

export default {
  name: "AboutEditorProject",
  components: {DescriptionsComplex, dropdownComponent, TagsComponent, UsefulLinksComplex},
  data(){
    return{
      complex:{},
      tags: [],
      descriptions: [],
      useful_links:[],
      types_of_sales:[],
      files: {
        img4: [],
        img6: [],
        img7: [],
        ImageInfrastructure: null,
        logo:null,
      },
    }
  },
  methods:{
    ...mapMutations({
      setComplexAll:'editorProjectData/setComplexAll',
    }),
    filterStatus(arr) {
      return arr.map(el => {
        return {...el, status: 'saved'}
      })
    },
    handleFileUploads (input) {
      const formData = new FormData()
      const file = this.$refs[input].files[0]
      const self = this
      if(input === 'img4' || input === 'img7') {
        this.files[input] = []
      }
      formData.append('file', file)
      axios.post('https://cdn.itrielt.ru/upload/file', formData).then(res => {
        if (res.data.status === 'success') {
          if (input === 'ImageInfrastructure'||input === 'logo') {
            self.files[input] = res.data.url
          }
          else {
            self.files[input] = [...self.files[input], res.data.url]
          }
        }
      })

    },
    saveComplex () {
      const obj = structuredClone(this.complex)
      if (this.tags.length) {
        obj.tags = this.tags.filter(el => el.status !== 'change' || (el.status === 'deleted' && !el.id))
      }
      if (this.descriptions.length) {
        obj.descriptions = this.descriptions.filter(el => el.status !== 'change' || (el.status === 'deleted' && !el.id))
      }
      if (this.useful_links.length) {
        obj.useful_links = this.useful_links.filter(el => el.status !== 'change' || (el.status === 'deleted' && !el.id))
      }
      obj.img4 = this.files.img4
      obj.img6 = this.files.img6
      obj.img7 = this.files.img7
      obj.ImageInfrastructure = this.files.ImageInfrastructure
      obj.logo = this.files.logo

      this.setComplexAll(obj)
      try {
        axios.post('/api/post-building?id='+this.$route.params.id, obj).then((res)=>{
          if (res.data?.tags) this.tags = this.filterStatus(res.data.tags)
          if (res.data?.descriptions) this.descriptions = this.filterStatus(res.data.descriptions)
          if (res.data?.useful_links) this.useful_links = this.filterStatus(res.data.useful_links)
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    deleteFile (array, index) {
      Vue.delete(this.files[array], index)
    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    this.types_of_sales=this.complex.types_of_sales.map(el => {
      return {
        id: el.id,
        text: el.name
      }
    })

    this.tags = this.filterStatus(this.complex.tags)
    this.descriptions = this.filterStatus(this.complex.descriptions)
    this.useful_links = this.filterStatus(this.complex.useful_links)

    if(this.complex.img4) this.files.img4 = JSON.parse(this.complex.img4)
    if(this.complex.img6) this.files.img6 = JSON.parse(this.complex.img6)
    if(this.complex.img7) this.files.img7 = JSON.parse(this.complex.img7)
    if(this.complex.ImageInfrastructure) this.files.ImageInfrastructure = this.complex.ImageInfrastructure
    if(this.complex.logo) this.files.logo = this.complex.logo
  }
}
</script>

<style scoped lang="scss">
.file-infrastructure {
  width: 100%;
  max-width: 300px;
  max-height: 200px;
  display: inline-block;
  margin: 10px;
  position: relative;
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
</style>