export default {
    methods: {
        getTextRole(role, subrole) {
            switch (role){
                case 'admin':
                    return this.$i18n.t('Админ')
                case 'bank':
                    return this.$i18n.t('РедакторБанк')
                case 'tests':
                    return this.$i18n.t('РедакторОбучение')
                case 'bitrix':
                    return this.$i18n.t('Менеджер')
                case 'client':
                    return this.$i18n.t('Клиент')
                case 'marketing':
                    return this.$i18n.t('РедакторМаркетинг')
                case 'sales':
                    return this.$i18n.t('РедакторПродажи')
                case 'tech':
                    return this.$i18n.t('РедакторТех')
                case 'user':
                    if(subrole ==='leader') return this.$i18n.t('Агентство')
                    return this.$i18n.t('Агент')
                case 'agency':
                    return this.$i18n.t('Агентство')
                case 'partner':
                    return this.$i18n.t('Партнер')
                case 'partners':
                    return this.$i18n.t('Партнер')
            }
        }
    }
}