<template>
  <div :class="''+ (page==='invest'?'dropdownInvest':'dropdown')" v-on-clickaway="checkClick">
    <div class="select-main-invest" @click="foc" v-if="page==='invest'">
      <div class="select-title">
        {{ Object.values(values).length > 0 ? (Object.values(values).length > 1 ? ($t('Выбрано')+' '+Object.values(values).length+' '+$t('зн')) : Object.values(values).join(', ') ) : (noneText?noneText:$t('НеВыбрано')) }}
      </div>
      <div class="icon-select">
        <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.46143 1L10.0532 9.5L18.1081 1" stroke="var(--blue-900-basic)" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
    <button :class="'btn btn-secondary dropdown-toggle '+ (Object.values(values).length > 0 ?'':'dropdown-toggle-default')" @click="foc" type="button" v-else>
      {{ Object.values(values).length > 0 ? (Object.values(values).length > 1 ? ($t('Выбрано')+' '+Object.values(values).length+' '+$t('зн')) : Object.values(values).join(', ') ) : (noneText?noneText:$t('НеВыбрано')) }}
    </button>
    <div :class="'dropdown-menu' +(show?' show':'')+ ' open-'+open">
      <div class="search-close-dropdown-wrapper" v-if="multipleSelect">
        <input class="input-dropdown" type="text" :placeholder="$t('поиск')" v-model="searchQuery" ref="myInput">
        <div class="cls-btn-select" @click="show=false">X</div>
      </div>
      <input v-else class="input-dropdown" type="text" :placeholder="$t('поиск')" v-model="searchQuery" ref="myInput">
      <a :class="'dropdown-cursor dropdown-item' + (values.indexOf(noneText) !== -1 ? ' active':'')" v-if="!noneHide" @click="selectValue(null)">{{ noneText }}</a>
      <slot v-for="element in sortedList">
        <a :class="'dropdown-cursor dropdown-item' + (values.indexOf(element.text) !== -1 ? ' active':'')" :style="{backgroundColor: element.bg, color: element.color}" @click="selectValues(element.id, element.text)" v-if="element.id !== undefined && element.color !== undefined"  :key="element.id">{{ element.text }}</a>
        <a :class="'dropdown-cursor dropdown-item' + (values.indexOf(element.text) !== -1 ? ' active':'')" @click="selectValues(element.id, element.text)" v-else-if="element.id !== undefined"  :key="element.id">{{ element.text }}</a>
        <a :class="'dropdown-cursor dropdown-item' + (values.indexOf(element) !== -1 ? ' active':'')" @click="selectValue(element)" v-else :key="element">{{ element }}</a>
      </slot>
      <slot v-for="group in SortedGroupList">
        <a :class="'dropdown-item-group'" :key="group.title">{{ group.title }}</a>
        <slot v-for="el in group.array">
          <a :class="'dropdown-padding dropdown-cursor dropdown-item' + (ids.indexOf(el.id) !== -1 ? ' active active_group':'')"  :style="{backgroundColor: el.bg}" @click="selectValues(el.id, el.name)" v-if="el.id !== undefined && el.bg !== undefined"  :key="el.id">{{ el.name }}</a>
          <a :class="'dropdown-padding dropdown-cursor dropdown-item' + (ids.indexOf(el.id) !== -1 ? ' active active_group':'')" @click="selectValues(el.id, group.title+': '+el.name)" v-else-if="el.id !== undefined"  :key="el.id">{{ el.name }}</a>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: "dropdownComponent",
  props: ['elements', 'value', 'multiple', 'open', 'none', 'noneHide', 'groups', 'page', 'multipleSelect'],
  data () {
    return {
      searchQuery:'',
      show: false,
      values: [],
      ids: [],
      noneText: this.$i18n.t('НеВажно')
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    initialization(){
      if (this.none) this.noneText = this.none
      if (this.multiple === false && this.value !== null) {
        if(this.elements !== undefined) {
          if (typeof this.elements[0] === 'object') {
            let obj = this.elements.find(o => o.id === this.value)
            if (obj) {
              this.values = [obj.text]
              this.ids = [obj.id]
            }
          } else {
            this.values = [this.value]
          }
        }

        if(this.groups !== undefined) {
          if (typeof this.groups[0] === 'object') {
            this.groups.forEach(group => {
              let obj = group.array.find(o => o.id === this.value)
              if (obj) {
                this.values = [group.title+': '+obj.name]
                this.ids = [obj.id]
              }
            })
          } else {
            this.values = [this.value]
          }
        }
      }
      else if (this.multiple === true && this.value !== null) {
        if(this.elements !== undefined) {
          if(typeof this.elements[0] === 'object' && (typeof this.value[0]==='object'|| typeof this.value[0]==='string')) {
            this.value.forEach(val => {
              let obj = this.elements.find(o => o.id === val)
              if(obj) {
                this.values.push(obj.text)
                this.ids.push(obj.id)
              }
            })
          } else {
            if(typeof this.elements[0] === 'object' && typeof this.value[0]==='number') {
              this.value.forEach(val => {
                let obj = this.elements.find(o => o.id === val)
                if(obj) {
                  this.values.push(obj.text)
                  this.ids.push(obj.id)
                }
              })
            } else {
              if(this.value!==undefined) {
                this.value.forEach(val => {
                  let obj = this.elements.find(o => o === val)
                  if (obj||obj===0) {
                    this.values.push(obj)
                    this.ids.push(obj)
                  }
                })
              }
            }
          }
        }

        if(this.groups !== undefined) {
          if(typeof this.groups[0] === 'object' && (typeof this.value[0]==='object'|| typeof this.value[0]==='string')) {
            this.value.forEach(val => {
              this.groups.forEach(group => {
                let obj = group.array.find(o => o.id === val)
                if(obj) {
                  this.values.push(group.title+': '+obj.name)
                  this.ids.push(obj.id)
                }
              })
            })
          } else {
            if(typeof this.groups[0] === 'object' && typeof this.value[0]==='number') {
              this.value.forEach(val => {
                this.groups.forEach(group => {
                  let obj = group.array.find(o => o.id === val)
                  if(obj) {
                    this.values.push(group.title+': '+obj.name)
                    this.ids.push(obj.id)
                  }
                })
              })
            } else {
              if(this.value!==undefined) {
                this.value.forEach(val => {
                  this.groups.forEach(group => {
                    let obj = group.array.find(o => o === val)
                    if (obj ||obj===0) {
                      this.values.push(obj)
                      this.ids.push(obj)
                    }
                  })
                })
              }
            }
          }
        }
      }
    },
    foc() {
      this.show=!this.show
      this.$nextTick(() => this.$refs.myInput.focus())
    },
    checkClick () {
      this.searchQuery=''
      this.show = false
    },
    selectValues (id, text) {
      if(!this.multiple) this.show=false
      this.searchQuery=''
      //this.show = false
      if (this.multiple === false) {

        if (text === this.noneText || text === null) {
          this.values = []
          this.ids = []
          this.$emit('input', null);
          this.$emit('selected',  null);
        }
        else {
          this.values = [text]
          this.ids = [id]
          this.$emit('input', this.ids[0]);
          this.$emit('selected',  this.ids[0]);
        }
      } else {
        if (text === this.noneText) {
          this.values = []
          this.ids = []
          this.$emit('input', null);
          this.$emit('selected',  null);
        } else {
          /*if (this.values.indexOf(text) !== -1) {
            var key = this.values.indexOf(text)
            Vue.delete(this.values, key)
          } else {
            this.values.push(text)
          }*/
          if (this.ids.indexOf(id) !== -1) {
            var keyd = this.ids.indexOf(id)
            Vue.delete(this.ids, keyd)
            var key = this.values.indexOf(text)
            Vue.delete(this.values, key)
          } else {
            this.ids.push(id)
            this.values.push(text)
          }
          this.$emit('input', this.ids);
          this.$emit('selected',  this.ids);
        }
      }
    },
    selectValue (val) {
      if(!this.multipleSelect) this.show = false
      if (this.multiple === false) {
        if (val === this.noneText || val === null) this.values = []
        else this.values = [val]
        this.$emit('input', this.values);
        this.$emit('selected', this.values);
      } else {
        if (val === this.noneText || val === null) {
          this.values = []
          this.$emit('input', null);
          this.$emit('selected', null);
        } else {
          if (this.values.indexOf(val) !== -1) {
            var key = this.values.indexOf(val)
            Vue.delete(this.values, key)
          } else {
            this.values.push(val)
          }
          this.$emit('input', this.values);
          this.$emit('selected', this.values);
        }
      }
    }
  },
  computed: {
    sortedList (){
      if(this.elements && this.elements[0]) {
        if (typeof this.elements[0] === 'object') {
          return [...this.elements].filter(post => post.text.toLowerCase().includes(this.searchQuery.toLowerCase()))
        } else return [...this.elements].filter(post => String(post).toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else return this.elements
    },
    SortedGroupList(){
      const newGroups=[]
      if(this.groups && this.groups[0]) {
        if (typeof this.groups[0] === 'object') {
          for(let i=0;i<this.groups.length;i++){
            let mas=[]
            mas=[...this.groups[i].array].filter(post => post.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
            newGroups.push({'title':this.groups[i].title, 'array':mas})
          }

          return newGroups.filter(grp=>grp.array.length!==0)
        }
      } else return this.groups
    }
  },
  watch:{
    value(newValue){
      this.values= []
      this.ids= []
      this.initialization()
    }
  }
}
</script>

<style lang="scss">
.search-close-dropdown-wrapper{
  position: sticky;
  top:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  width: 100%;
  background-color: var(--white-basic);
  .input-dropdown{
    width: 100%;
  }
  .cls-btn-select{
    cursor: pointer;
    font-size: 18px;
    color: var(--black-basic);
    font-weight: 600;
  }
}
.dropdownInvest{
  cursor: pointer;
  position: relative;
  min-height: 53px;
  width: 100%;
  min-width: 300px;
  background: var(--white-basic);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  .select-main-invest{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding-left: 15px;
    .select-title{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.1px;
      color: var(--blue-900-basic);
      padding: 5px;
    }
    .icon-select{
      padding-right: 15px;
    }
  }
  .dropdown-menu.show{
    overflow: auto;
  }
}
.active_group {
  color: white !important;
  font-weight: bold !important;
  text-shadow: 1px 0 1px var(--black-basic),
  0 1px 1px var(--black-basic),
  -1px 0 1px var(--black-basic),
  0 -1px 1px  var(--black-basic);
}
.dropdown-padding{
  padding-left: 35px;
}
.dropdown-cursor{
  cursor: pointer!important;
}
.dropdown-item-group{
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  clear: both;
  color: var(--dropdown-group-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-weight: bold
}
.dropdown-item-group:hover{
  color: var(--black-basic);
}
.dropdown-toggle {
  white-space: pre-wrap;
}
.dropdown-menu.show {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
.dropdown-menu.show.open-top {
  bottom: 100%;
  height: auto;
  top: auto;
}
.input-dropdown{
  border: 0;
  padding: 10px 16px;
}
.dropdown{
button{
  display: flex !important;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 12px;
  outline: none!important;
  border: 0!important;
  box-shadow: none !important;
}
}
.dropdown{
button{
  display: inline-block;
  padding: 12px 24px;
  width: 100%;
  background: var(--dropdown-title-back);
  border-radius: 100px !important;
  color: var(--gray-800-basic);
  border: none;
}
}
.dropdown button:hover, .dropdown button:active, .dropdown button:focus {
  background-color: var(--dropdown-title-back);
  outline: none!important;
  opacity: 1!important;
  color: var(--gray-800-basic) !important;
  border: 0 !important;
}
.dropdown button::-moz-focus-inner {
  border: 0 !important;
  outline: none!important;
}
.dropdown-menu {
  padding: 0!important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: url('../../public/my/CaretDown.svg')!important;
  border: 0!important;
}
</style>
